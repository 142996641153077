<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
           <th class="transit">Carrier Name</th>
            <th class="transit">Transit Type</th>
            <th class="transit">Pickup Location Country/State/Subrub/Postcode </th>
           
            <th class="transit">Delivery Location Country/State/Subrub/Postcode</th>
           
            <th class="transit">Transit Days</th>
            <th>Action</th>
        </tr>
        <tr v-for="(service,i) in services" :key="i">
          <td>
            <div class="label_and_element_wrapper">
              <select v-model="service.provider_id" :disabled="!service.is_edit_mode">
                <option value disabled>Select Carrier</option>
                <option
                  v-for="(carrier,i) in carriers"
                  :key="'carriers'+i"
                  :value="carrier.id"
                >{{carrier.name}}</option>
              </select>
            </div>
          </td>
             <td>
            <div class="label_and_element_wrapper">
              <!-- <input   type="text"  placeholder="Enter Character Limit 30"  v-model="service.transit_type" disabled   :maxlength="max"  /> -->
                 <select  v-model="service.transit_type"  :disabled="!service.is_edit_mode">
                    <option value="" disabled>Select Transit Type</option>
                    <option value = "postcodes">Post Codes</option>
                    <option value = "city">City</option> 
                    <option value = "state">State</option>
                    <option value = "country">Country</option>
                    </select>
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input  type="text"  placeholder="Enter Character Limit 30" v-model="service.from_origin" :disabled="!service.is_edit_mode" :maxlength="max" v-if= "service.transit_type != 'country'" />

              <input type="text" placeholder="Enter Character Limit 30" v-model="services[i].from_origin"  :disabled="!service.is_edit_mode"  @focus="show_pickup_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max" v-if= "service.transit_type == 'country'"/>
                <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion && services.editingIndex==i">
                  <span 
                    v-for="(address,i) in filteredPickupAddress" 
                    :key="'from_origin_'+i"
                    @click="populatePickupAddress(address,i)"
                    >
                    {{address.name}} ({{address.code}})
                  </span>
                </div>


            </div>
          </td>
          

          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter Character Limit 30" v-model="service.to_destination" :disabled="!service.is_edit_mode" :maxlength="max" v-if= "service.transit_type != 'country'" />

              <input type="text" placeholder="Enter Character Limit 30" v-model="services[i].to_destination"  :disabled="!service.is_edit_mode"   @focus="show_destination_suggestion = true" @blur="hideSuggestions" autocomplete="new-password1" :maxlength="max" v-if= "service.transit_type == 'country'"/>
                <div class="dynamic_data_wrapper" v-if="show_destination_suggestion && services.editingIndex==i">
                  <span 
                    v-for="(address,i) in filteredDeliveryAddress" 
                    :key="'to_destination_'+i"
                    @click="populateDeliveryAddress(address,i)"
                    >
                    {{address.name}} ({{address.code}})
                  </span>
                </div>

            </div>
          </td>
         

           <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter Character Limit 30" v-model="service.transit_days"  :disabled="!service.is_edit_mode"  :maxlength="max" />
            </div>
          </td>

          <td class="righted">
            <a class="table_action_btn" title="DELETE" @click="deleteTransitTime(service.id)">DELETE</a>
            <a
              class="table_action_btn"
              title="CANCEL"
              v-if="service.is_edit_mode"
              @click="cancelTransitTime()"
            >CANCEL</a>
            <a
              class="table_action_btn"
              title="UPDATE"
              v-if="service.is_edit_mode"
              @click="updateTransitTime(service)"
            >UPDATE</a>
            <a
              class="table_action_btn"
              title="EDIT"
              v-if="!service.is_edit_mode"
              @click="service.is_edit_mode=true, services.editingIndex=i  "
            >EDIT</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "TransitTime",
  components: {},
  computed:{
    filteredPickupAddress(){    
      return this.countries.filter(element => {
        if(element.name.toLowerCase().includes(this.services[this.services.editingIndex].from_origin.toLowerCase()) || element.code.toLowerCase().includes(this.services[this.services.editingIndex].from_origin.toLowerCase())){
          return element;
        }
      })
    },
    filteredDeliveryAddress(){
      return this.countries.filter(element => {
          console.log(element);
        if(element.name.toLowerCase().includes(this.services[this.services.editingIndex].to_destination.toLowerCase())|| element.code.toLowerCase().includes(this.services[this.services.editingIndex].to_destination.toLowerCase())){
          console.log(element);
          return element;
        }
      })
    }
  },
  data() {
    return {
      max: 30,
      services: [],
      carriers: [],
      countries:[],
      show_pickup_suggestion:false,
      show_destination_suggestion:false,
    };
  },
  created() {
    this.fetchCarriers();
    this.fetchTransitTime();
    this.fetchCountries();
  },
  methods: {
    fetchCarriers() {
       this.axios.get("/api/common/transitProviders")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchTransitTime() {
      this.axios
        .get("/api/transittime/view")
        .then(response => {
          this.services = response.data.services.map(element => {
            element.is_edit_mode = false;
             element.transit_type_name = element.transit_type == 'country' ? 'Country' : element.transit_type == 'postcodes' ? 'Post Codes' : element.transit_type == 'suburbs' ? 'Suburbs' : element.transit_type == 'state' ? 'State' : '';

            return element;
          });
        })
        .catch(error => {
          this.toast.error();
        });
    },
    //    fetchTransitType(data) {

    //     if(data.transit_type == 'country')
    //         this.fetchCountries();

    //   // this.axios.get("/api/provider/carrier/"+data.provider_id)
    //   //   .then(response => {
    //   //   data.transit_type = response.data.provider.transit_type;
    //   //   data.transit_type_name  = data.transit_type == 'country' ? 'Country' : data.transit_type == 'postcodes' ? 'Post Codes' : data.transit_type == 'suburbs' ? 'Suburbs' : data.transit_type == 'state' ? 'State' : '';
         
    //   //     console.log(data.transit_type_name);

    //   //     })
       
    //   //   .catch(error => {
    //   //     console.log(error);
    //   //   });
      
    // },

    fetchCountries(){
      this.axios.get("/api/common/countries")
      .then(response => {
          this.countries = response.data.countries;
           console.log(this.countries);
      })
      .catch(error =>{
          this.toast.error();
      });
    },

    hideSuggestions(){
      setTimeout(() => {
        this.show_pickup_suggestion = false;
        this.show_destination_suggestion = false;
      },200);
    },

    populatePickupAddress(value,i){
     
      this.services[this.services.editingIndex].from_origin = value.name;
       
    },
    populateDeliveryAddress(value,i){
     
      this.services[this.services.editingIndex].to_destination = value.name;
      
    },


    
    updateTransitTime(data) {
      this.axios
        .put("/api/transittime/" + data.id, data)
        .then(response => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteTransitTime(id) {
      if (confirm("Are you sure you want to delete this Transit Time?")) {
        this.axios
          .delete("/api/transittime/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchTransitTime();
          })
          .catch(error => {
            this.toast.error();
          });
      }
    },
    cancelTransitTime() {
      this.fetchTransitTime();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 25%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
.transit
{
  width:20% !important;
}
</style>