<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Select Carrier
                      <span>*</span>
                    </label>
                    <select v-model="service.provider_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Carrier</option>
                      <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
            <!--         <td>
                  <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Transit Type
                      <span>*</span>
                    </label>
                 
                     <input type="text" placeholder=" "  :class="errors[0] ? 'warning-border':''"  v-model="service.transit_type_name" :maxlength="max"  disabled />
                  </div>
                  </ValidationProvider>
                </td> -->

                 <td>
                  <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim >
                  <div class="label_and_element_wrapper">
                    <label>
                      Transit Type
                      <span>*</span>
                    </label>
                 
                    <select  v-model="service.transit_type" @change="fetchTransitType">
                    <option value="" disabled>Select Transit Type</option>
                    <option value = "postcodes">Post Codes</option>
                    <option value = "city">City</option> 
                    <option value = "state">State</option>
                    <option value = "country">Country</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Pickup Location Country/State/Subrub/Postcode
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.from_origin" :maxlength="max" v-if= "service.transit_type != 'country'"/>


                    <input type="text" placeholder="Enter Character Limit 30" v-model="service.from_origin"  :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'"   @focus="show_pickup_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max" v-if= "service.transit_type == 'country'"/>
                      <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion">
                        <span 
                          v-for="(address,i) in filteredPickupAddress" 
                          :key="'from_origin_'+i"
                          @click="populatePickupAddress(address)"
                          >
                          {{address.name}} ({{address.code}})
                        </span>
                      </div>

                  </div>
                  </ValidationProvider>
                </td>
              

                
            
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Delivery Location Country/State/Subrub/Postcode
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''"  v-model="service.to_destination" :maxlength="max" v-if= "service.transit_type != 'country'" />

                    <input type="text" placeholder="Enter Character Limit 30" v-model="service.to_destination"  :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'"   @focus="show_destination_suggestion = true" @blur="hideSuggestions" autocomplete="new-password1" :maxlength="max" v-if= "service.transit_type == 'country'"/>
                      <div class="dynamic_data_wrapper" v-if="show_destination_suggestion">
                        <span 
                          v-for="(address,i) in filteredDeliveryAddress" 
                          :key="'to_destination_'+i"
                          @click="populateDeliveryAddress(address)"
                          >
                          {{address.name}} ({{address.code}})
                        </span>
                      </div>

                  </div>
                  </ValidationProvider>
                </td>
              
                
            </tr>
             <tr>
                <td>
                  <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Transit Days
                     <span>*</span>
                    </label>
                    <input type="text" placeholder="Transit Days"  :class="errors[0] ? 'warning-border':''"  v-model="service.transit_days" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
          
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TransitTime",

   computed:{
    filteredPickupAddress(){
      return this.countries.filter(element => {
        if(element.name.toLowerCase().includes(this.service.from_origin.toLowerCase()) || element.code.toLowerCase().includes(this.service.from_origin.toLowerCase())){
          return element;
        }
      })
    },
    filteredDeliveryAddress(){
      return this.countries.filter(element => {
          console.log(element);
        if(element.name.toLowerCase().includes(this.service.to_destination.toLowerCase())|| element.code.toLowerCase().includes(this.service.to_destination.toLowerCase())){
          console.log(element);
          return element;
        }
      })
    }
  },

  data(){
    return {
      max:30,
      carriers:[],
      countries:[],
      show_pickup_suggestion:false,
      show_destination_suggestion:false,
      service:{
        provider_id:"",
        transit_type:"",
        from_origin :"",
        to_destination :"",
        transit_days:"",
        transit_type_name:"",
        
      }
    }
  },
  created() {
    this.fetchCarriers();
     
  },
  methods: {
    fetchCarriers(){
      this.axios.get("/api/common/transitProviders")
      .then(response => {
          this.carriers = response.data.providers;
           
      })
      .catch(error =>{
          this.toast.error();
      });
    },



     fetchTransitType() {
      
      // if(this.service.provider_id){
      // this.axios.get("/api/provider/carrier/"+this.service.provider_id)
      //   .then(response => {
      //     this.service.transit_type = response.data.provider.transit_type;
      //     this.service.transit_type_name  = this.service.transit_type == 'country' ? 'Country' : this.service.transit_type == 'postcodes' ? 'Post Codes' : this.service.transit_type == 'suburbs' ? 'Suburbs' :this.service.transit_type == 'state' ? 'State' :  '';
      //      this.service.transit_days = response.data.provider.default_transit_days;
      //     console.log(this.service.transit_type_name);
          
      //     if(this.service.transit_type == 'country')
      //       this.fetchCountries();

      //     })
       
      //   .catch(error => {
      //     console.log(error);
      //   });
      // }

      if(this.service.transit_type == 'country')
            this.fetchCountries();
    },

    fetchCountries(){
      this.axios.get("/api/common/countries")
      .then(response => {
          this.countries = response.data.countries;
           console.log(this.countries);
      })
      .catch(error =>{
          this.toast.error();
      });
    },

    hideSuggestions(){
      setTimeout(() => {
        this.show_pickup_suggestion = false;
        this.show_destination_suggestion = false;
      },200);
    },

    populatePickupAddress(value){
     
      this.service.from_origin = value.name;
      
    },
    populateDeliveryAddress(value){
     
      this.service.to_destination = value.name;
      
    },


    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/transittime/",this.service)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
</style>